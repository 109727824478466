
import { Component, Vue, Watch, Provide } from "vue-property-decorator";
import webSocket from "@/components/WebSocket.vue";
import HeaderLogin from "@/components/HeaderLogin.vue";
import iHeader from "@/components/Header.vue";
import iFooter from "@/components/Footer.vue";
import IconRight from "@/components/IconRight.vue";
import iCode from "@/components/Code.vue";
import { mapGetters } from "vuex";
import { number } from "echarts";
@Component({
  components: {
    webSocket,
    HeaderLogin,
    iHeader,
    iFooter,
    IconRight,
    iCode
  },
  computed: {
    ...mapGetters(["token", "userInfo"])
  }
})
export default class App extends Vue {
  @Provide() windowScreenWidth:number = document.body.clientWidth //动态的屏幕宽度
  @Provide() timer:boolean = false 
  

  @Watch("windowScreenWidth", { immediate: true, deep: true })
  private screenWidth(newVal: number, oldVal: number): void {
    const _this: any = this;
     // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        if(!_this.timer){
            // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
            _this.windowScreenWidth = newVal;
            // 类型断言
            _this.windowScreenWidth < 1280 ? (document.body.style as any ).zoom = 0.8 : (document.body.style as any ).zoom = 1
            _this.timer = true
            setTimeout(function(){
                // 打印screenWidth变化的值
                _this.timer = false
            },400)
        }
  }

  @Watch("token", { immediate: true, deep: false })
  private iDataChange(): void {
    const _this: any = this;
    if (_this.$base.isNull(localStorage.getItem("_TOKEN"))) return;
    const data: string = _this.$getDecryptBy("_USERINFO");
    _this.$store.dispatch("GetMyCartsFn", _this);
    _this.$store.dispatch("GetMyCartsNumAndPriceFn", _this);
    _this.$store.dispatch("GetMyAddressFn", _this);
    console.log('-----C-----');
    console.log(data);
    // if (data.indexOf("暂未存储") > -1) {
    //   _this.$store.dispatch("GetInfoFn", _this);
    // }
    _this.$store.dispatch("GetInfoFn", _this);
  }
  created() {
    const _this: any = this;
    if (_this.$base.isMobile()) {
      window.location.href = "http://m.shop.sanxiaxiu.com";
    }
  }
  mounted() {
    const _this: any = this;
    var t = window.devicePixelRatio; // 获取下载的缩放 125% -> 1.25    150% -> 1.5
    // _this.windowScreenWidth = document.body.clientWidth; // 获取屏幕的动态宽度 做到自适应
    window.onresize = () => {
        return (() => {
            let screenWidth = _this.windowScreenWidth
            _this.windowScreenWidth = screenWidth
        })()
    }
    let a = setTimeout(() => {
      let obj = document.getElementById("loading-first-body");
      if (obj) {
        obj.style.display = "none";
        clearTimeout(a);
      }
    }, 500);
  }
}
