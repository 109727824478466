// import request from "../request"
import request from "../http"
import {
    ClickCollectionReq,
    GetByDictTypeListReq,
    GetBySinglePageContentListReq, UploadFileByBase64Req
} from "@/interface/res/common";
// 用户信息
export function GetInfoApi (params:{} = {}){
    return request({
        url: '/shop/account/info/getInfo',
        method: 'get',
        params,
        isToken:true
    })
}

// 点击收藏
export function ClickCollectionApi (params:ClickCollectionReq){
    return request({
        url: '/shop/account/collection/clickCollection',
        method: 'post',
        params,
        isToken:true
    })
}

// 查询字典
export function GetByDictTypeListApi (params:GetByDictTypeListReq){
    return request({
        url: '/shop/common/getByDictTypeList',
        method: 'get',
        params,
        isToken:true
    })
}

// 查询单页内容
export function GetBySinglePageContentListApi (params:GetBySinglePageContentListReq){
    return request({
        url: '/shop/common/getBySinglePageContentList',
        method: 'get',
        params,
        isToken:true
    })
}
// 消息通知
export function GetNoticeApi (params:{} = {}){
    return request({
        url: '/shop/notice/getNotice',
        method: 'get',
        params,
        isToken:true
    })
}

// 我的优惠卡
export function getUsableList (params:{} = {}){
    return request({
        url: 'shop/account/coupon/getUsableList',
        method: 'get',
        params,
        isToken:true
    })
}
// 我的优惠卡
export function CouponGetList (params:{} = {}){
    return request({
        url: '/shop/account/coupon/getList',
        method: 'get',
        params,
        isToken:true
    })
}


// 购物车列表
export function GetMyCartsApi (params:{} = {}){
    return request({
        url: '/shop/shoppingCart/getMyCarts',
        method: 'get',
        params,
        isToken:true
    })
}

// 获取购物车分类数量
export function GetMyCartsNumAndPriceApi (params:{} = {}){
    return request({
        url: '/shop/shoppingCart/getMyCartsNumAndPrice',
        method: 'get',
        params,
        isToken:true
    })
}

// 获取购物车分类数量
export function AliPcPayApi (params:{} = {}){
    return request({
        url: '/shop/pay/ali/pcPay',
        method: 'get',
        params,
        isToken:true
    })
}

// base64转url
export function UploadFileByBase64Api (params:UploadFileByBase64Req){
    return request({
        url: '/shop/common/uploadFileByBase64',
        method: 'post',
        params,
        isToken:true
    })
}
