import { StoreType } from '@/interface/store/index'
import { getDecryptBy } from "@/utils/encryption";

export default {
	userInfo: (state: StoreType) => {
		const data: string = getDecryptBy('_USERINFO')
		if (state.user.userInfo.accountId == -1) {
			if (data.indexOf('暂未存储') == -1) {
				return state.user.userInfo = JSON.parse(data)
			}
			return state.user.userInfo
		}
		return state.user.userInfo
	},
	token: (state: StoreType) => {
		const data: any = localStorage.getItem("_TOKEN")
		if (state.user.token == '') {
			if (data != null || data != 'null') {
				return state.user.token = data
			}
			return state.user.token
		}
		return state.user.token
	},
	shoppingCartList: (state: StoreType) => state.shoppingCart.list,
	shoppingCartListTotal: (state: StoreType) => state.shoppingCart.listTotal,
	shoppingCartNum: (state: StoreType) => state.shoppingCart.listNum,
	serviceShow: (state: StoreType) => state.service.show,
	
	serviceUnread: (state: StoreType) => state.service.unread,
	serviceMessage: (state: StoreType) => state.service.message,
	serviceList: (state: StoreType) => state.service.list,
	serviceHistory: (state: StoreType) => state.service.isHistory,
	serviceCustomizeInfo: (state: StoreType) => state.service.customizeInfo,
	addressList: (state: StoreType) => state.address.list,
	payData: (state: StoreType) => state.pay.data,
	appShow: (state: StoreType) => state.user.appShow,
	countNum: (state: StoreType) => state.user.countNum,
	orderStatuMsg: (state:StoreType) => state.webSocket.orderStatu,//订单信息状态
}
